var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit column no-wrap relative-position",staticStyle:{"overflow":"hidden"}},[_c('header',{staticClass:"row q-px-md q-py-sm justify-between items-center"},[_c('div',[_c('q-input',{staticStyle:{"min-width":"300px"},attrs:{"debounce":"200","dense":"","filled":"","label":"搜索图片名称"},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}})],1),_c('div',{staticClass:"row items-center"},[_c('div'),_c('q-btn',{staticClass:"row items-center relative-position",attrs:{"color":"primary"}},[_c('input',{staticClass:"absolute fit cursor-pointer",staticStyle:{"opacity":"0"},attrs:{"multiple":"","type":"file"},on:{"input":_vm.handleUpload}}),_c('q-icon',{attrs:{"name":"fas fa-upload","size":"12px"}}),_c('span',{staticClass:"q-ml-sm"},[_vm._v("上传图片")])],1)],1)]),_vm._m(0),(_vm.filteredObjects && _vm.filteredObjects.length)?_c('q-scroll-area',{staticClass:"col-grow column"},[_c('q-list',{staticClass:"row q-pa-md img-list q-gutter-xs"},_vm._l((_vm.filteredObjects),function(obj){return _c('q-item',{key:obj.name,staticClass:"q-pa-md rounded-borders column no-wrap items-center",style:({
          width: '188px',
          height: '200px',
          border:
            _vm.selectedImageObj && obj.name === _vm.selectedImageObj.name
              ? '2px solid var(--q-color-primary)'
              : '2px solid transparent',
        }),attrs:{"clickable":""},on:{"click":function($event){return _vm.handleItemClick(obj)}}},[_c('div',{staticClass:"q-pa-xs full-width rounded-borders shadow-1 column items-center justify-center",staticStyle:{"height":"calc(100% - 30px)"},on:{"click":function($event){return _vm.copyImageUrl(obj.url)}}},[_c('img',{staticClass:"rounded-borders",staticStyle:{"max-width":"100%","max-height":"100%"},attrs:{"src":obj.url,"loading":"lazy","draggable":"false"}})]),_c('div',{staticClass:"full-width text-caption text-grey q-mt-sm ellipsis",staticStyle:{"height":"30px"}},[_vm._v(" "+_vm._s(obj.name.split("/").reverse()[0])+" ")]),_c('q-tooltip',{staticClass:"column items-center"},[_c('p',[_vm._v(_vm._s(obj.name.split("/").reverse()[0]))]),_c('p',[_vm._v("更新于 "+_vm._s(new Date(obj.lastModified).toLocaleString()))])])],1)}),1)],1):_c('div',{staticClass:"col-grow column items-center justify-center text-caption text-grey"},[_vm._v(" 未找到任何资源 ")]),(_vm.selectMode)?_c('footer',{staticClass:"select-mode-toolbar absolute full-width q-py-sm q-px-md bg-primary row no-wrap justify-between",style:({
      transform: ("translateY(" + (!!_vm.selectedImageObj ? '0' : '100%') + ")"),
      transition: 'all 0.23s ease',
      bottom: 0,
    })},[_c('div'),_c('div',{staticClass:"row items-center"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup",value:(true),expression:"true"}],attrs:{"color":"white","disable":!_vm.selectedImageObj,"text-color":"primary"},on:{"click":function($event){return _vm.$emit('select', _vm.selectedImageObj)}}},[_vm._v(" 确认 ")])],1)]):_vm._e(),_c('q-dialog',{model:{value:(_vm.uploading),callback:function ($$v) {_vm.uploading=$$v},expression:"uploading"}},[_c('q-card',{staticClass:"column q-pa-md items-center"},[_c('q-linear-progress',{staticClass:"q-my-md",attrs:{"value":_vm.uploadingIndex / _vm.uploadListLength}}),_c('div',[_c('span',[_vm._v(" 正在批量上传图片， ")]),_c('span',[_vm._v(_vm._s(_vm.uploadingIndex))]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.uploadListLength))])])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"row q-px-md items-center reverse"},[_c('div',{staticClass:"text-caption text-grey"},[_vm._v("如果上传同名文件，会覆盖")])])}]

export { render, staticRenderFns }