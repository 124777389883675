import { SET_APP_ID } from "../mutation.type";

const state = {
  appId: null,
};

const mutations = {
  [SET_APP_ID]: (state, appId) => {
    state.appId = appId;
  },
};

export default {
  state,
  mutations,
};
