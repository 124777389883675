import { ComponentItem } from './../../../../../types/components/component-item.interface';
import { ResponseBody } from "../../../../../types/response/response-body.interface";
import { ApiService } from "@/api";

export const updatePageComponents = (
  pageId: string,
  newComponents: ComponentItem[],
  token: string
): any => {
  return ApiService.baseAction(
    `page/update/components/${pageId}`,
    {
      data: {
        coms: newComponents,
      },
      headers: {
        token,
      },
    },
    "POST"
  );
};
