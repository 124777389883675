

































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import * as OSS from "ali-oss";
import { getSts } from "@/api/endpoints/oss/getSts";
import { ResponseBody } from "../../../types/response/response-body.interface";
import { OssStsRes } from "../../../types/oss/oss-sts.interface";
import { SET_OSS_STS_CLIENT } from "@/store/mutation.type";
import { styleLog } from "@/utils/style-log";

const ossStsExpiredErrName = [
  "SecurityTokenExpiredError",
  "InvalidAccessKeyIdError",
];

@Component({
  components: {},
})
export default class ResourceManger extends Vue {
  @Prop({ type: Boolean, default: false })
  public readonly selectMode!: boolean;

  public selectedImageObj: any = null;

  public uploading = false;
  public uploadListLength = 0;
  public uploadingIndex = 0;

  public searchKeyword = "";
  public objectList = [];

  get appId() {
    return this.$store.state.app.appId;
  }

  get filteredObjects(): Record<string, any>[] {
    return (this.objectList || []).filter((obj: any) => {
      const reg = new RegExp(this.searchKeyword, "gi");
      if (!obj || !obj.name) return false;
      const name: string = obj.name.split("/").reverse()[0];
      return name.match(reg);
    });
  }

  async beforeMount() {
    await this.getClient();
  }
  async mounted() {
    await this.getResources();
  }

  public handleItemClick(obj: any) {
    if (this.selectMode) {
      if (this.selectedImageObj && this.selectedImageObj.name === obj.name)
        this.selectedImageObj = null;
      else this.selectedImageObj = obj;
    }
  }

  public copyImageUrl(url: string) {
    if (this.selectMode) return;
    const aux = document.createElement("textarea");
    aux.value = url;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    this.$q.notify({ color: "primary", message: "已拷贝图片链接" });
  }

  /**
   * 批量上传图片
   */
  public async handleUpload(e: Event) {
    if (!e || !e.target) return;
    const target = e.target;
    const files: FileList = (target as any).files;

    this.uploading = true;
    this.uploadingIndex = 0;
    this.uploadListLength = files.length;

    const client = await this.getClient();
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      try {
        this.uploadingIndex++;
        if (!file) throw Error("File Not Found");
        const filename = file.name;
        const uploadRes = await client.put(`${this.appId}/${filename}`, file);
      } catch (err) {
        console.log(err);
      }
    }
    await this.getResources();
    this.uploading = false;
  }

  private async getClient(reset = false) {
    // 1. 从 vuex 获取
    let client = this.$store.state.oss.stsClient;
    if (client && !reset) return client;
    client = await this.initClient();
    this.$store.commit(SET_OSS_STS_CLIENT, client);
    return client;
  }

  private async initClient() {
    const stsRes: ResponseBody<OssStsRes> = await getSts(this.appId);
    const ossSts = stsRes.data;
    const client = new OSS({
      region: process.env.VUE_APP_OSS_REGION,
      accessKeyId: ossSts.AccessKeyId,
      accessKeySecret: ossSts.AccessKeySecret,
      stsToken: ossSts.SecurityToken,
      refreshSTSToken: async () => {
        const stsRes: ResponseBody<OssStsRes> = await getSts(
          this.$store.state.app.appId
        );
        const ossSts = stsRes.data;
        return {
          accessKeyId: ossSts.AccessKeyId,
          accessKeySecret: ossSts.AccessKeySecret,
          stsToken: ossSts.SecurityToken,
        };
      },
      refreshSTSTokenInterval: ossSts.Expiration,
      bucket: process.env.VUE_APP_OSS_BUCKET,
    });
    return client;
  }

  public async getResources() {
    const client = await this.getClient();
    try {
      const listRes = await client.list({
        prefix: this.appId,
        ["max-keys"]: 1000,
      });
      this.objectList = listRes.objects.sort((obj1, obj2) => {
        const t1 = new Date(obj1.lastModified);
        const t2 = new Date(obj2.lastModified);
        return t2.getTime() - t1.getTime();
      });
      console.log(this.objectList);
    } catch (err) {
      console.log(err.name);
      if (ossStsExpiredErrName.indexOf(err.name) > -1) {
        styleLog.danger("OSS_CLIENT_EXPIRED");
        await this.getClient(true);
        await this.getResources();
      }
    }
  }
}
