import Vue from "vue";
import Vuex from "vuex";

// import chartOutputModule from "./modules/chart-output.module";
// import templateModule from "./modules/template.module";

// import { SET_CHART_OUTPUT_METHOD } from "./mutation-type";

Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const basicModules = require.context("./modules", true, /\.js$/);

const mods = [basicModules];

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = mods.reduce((modules, mod) => {
  mod.keys().forEach((modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = mod(modulePath);
    (modules as any)[moduleName] = value.default;
  });
  return modules;
}, {});

const store = new Vuex.Store({
  modules,
});

export default store;
