









import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import QyCMS from "qycms-coms";
import { ComponentItem } from "../../../types/components/component-item.interface";

const components = QyCMS.avaiableComponentNames.reduce(
  (res: Record<string, any>, name: string) => {
    res[name] = QyCMS[name];
    return res;
  },
  {}
);
@Component({
  components: {
    ...components,
  },
})
export default class ComponentPreviewer extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  private readonly component!: ComponentItem;

  get comName() {
    const { component } = this;
    const templated = (component || {}).template_id;

    return `qy-cms-${templated}`;
  }
  get avaiable() {
    return QyCMS.avaiableComponentNames.indexOf(this.comName) > -1;
  }
}
