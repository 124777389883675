
const tplFormComponentPrefix = "tpl-form";
const requireComponent = require.context(
  "./component-item-form",
  false,
  /\w+\.(vue)$/,
  "lazy"
);
// 处理懒加载
const promisedComponets = requireComponent.keys().reduce((result, fileName) => {
  const reg = /^.*\/(\w+)\.vue$/;

  const componentName =
    tplFormComponentPrefix + "-" + fileName.replace(reg, "$1").toLowerCase();
  const promisedComponent = requireComponent(fileName)
    .then((componentConfig: any) => {
      return componentConfig.default || componentConfig;
    })
    .catch((error: any) => {
      console.log(error);
      return;
    });
  return {
    ...result,
    ...{
      [componentName]: () => promisedComponent,
    },
  };
}, {});

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TemplateConfigNode } from "../../../types/templates/template.interface";
import TinyMCEEdit from "./component-item-form/Richtext.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    ...promisedComponets,
    draggable,
  },
})
export default class ComponentItem extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly config!: Record<string, any>;

  @Prop({ type: Array, default: () => [] })
  readonly templateStructure!: Array<TemplateConfigNode>;

  private getTitleName(value) {
    return Object.keys(value)
      .filter((key) => {
        return key.toString() === "title" || key.toString() === "name";
      })
      .map((key) => (value || ({} as any))[key] as string)
      .join(",");
  }

  render(h) {
    const { config } = this;
    const getValueByPath = (path: (string | number)[]): any => {
      let p = config;
      for (let key of path) {
        p = p[key];
        if (p === undefined || p === null) break;
      }
      return p;
    };
    const formBuilder = (
      conf: TemplateConfigNode,
      path: (string | number)[] = []
    ): any => {
      // path.push(conf.key);
      const newPath = [...path, conf.key];
      const value = getValueByPath(newPath);
      return (
        <div
          class="q-pa-md q-ml-xs rounded-borders q-mb-xs"
          style="background: rgba(0,0,0,0.06)"
        >
          <p class="text-caption">
            <span class="text-bold q-pa-sm q-mb-sm text-grey-9">
              {conf.label}
            </span>

            <span class="text-grey-7" style="font-size: 0.6rem">
              key:
            </span>
            <span style="font-size: 0.6rem">{conf.key} | </span>

            <span style="font-size: 0.6rem" class="text-grey-7">
              type:
            </span>
            <span style="font-size: 0.6rem">{conf.type}</span>
          </p>
          {(() => {
            switch (conf.type) {
              case "array":
                return arrayBuilder(
                  conf.array_value as TemplateConfigNode[],
                  newPath,
                  value
                );
              case "tree":
                return treeBuilder(
                  conf.sub_tree as TemplateConfigNode[],
                  newPath
                );

              default:
                return tplFormBuilder(conf, newPath, value);
            }
          })()}
        </div>
      );
    };

    const tplFormBuilder = (
      conf: TemplateConfigNode,
      path: (string | number)[],
      value: any
    ) => {
      return h(`${tplFormComponentPrefix}-${conf.type}`, {
        props: {
          conf,
          value,
        },
        on: {
          change: (val: any) => this.$emit("updateValue", { path, value: val }),
        },
      });
      // (
      //   <component
      //     is={`${tplFormComponentPrefix}-${conf.type}`}
      //     conf={conf}
      //     value={value}
      //     onChange={(val: any) =>
      //       this.$emit("updateValue", { path, value: val })
      //     }
      //   />
      // );
    };

    const treeBuilder = (
      confs: TemplateConfigNode[],
      path: (string | number)[]
    ) => confs.map((conf) => formBuilder(conf, path));

    const arrayBuilder = (
      confs: TemplateConfigNode[],
      path: (string | number)[] = [],
      valueArr = []
    ) => {
      return (
        <div id="component-item" class="column">
          <draggable
            list={valueArr}
            handle=".q-expansion-item__container > .q-item:nth-child(1)"
          >
            <transition-group>
              {valueArr.map((value, index) => (
                <q-expansion-item key={index} dense-toggle switch-toggle-side>
                  <template slot="header">
                    <div class="row justify-between full-width items-center no-wrap">
                      <span
                        style="max-width: 400px"
                        class="ellipsis text-bold text-grey-8"
                      >
                        <span class="text-primary text-light">标题：</span>
                        {this.getTitleName(value)
                          ? this.getTitleName(value)
                          : "无标题的列表内容"}
                      </span>
                      <div class="row no-wrap items-center reverse">
                        <q-btn
                          onClick={(e: Event) => {
                            e.stopPropagation();
                            this.$emit("removeArrItem", { path, index });
                          }}
                          class="q-px-xs"
                          flat
                          dense
                          color="red"
                        >
                          <q-icon style="font-size: 12px" name="fas fa-trash" />
                        </q-btn>

                        <q-btn
                          onClick={(e: Event) => {
                            e.stopPropagation();
                            this.$emit("moveArrItem", {
                              path,
                              index,
                              offset: -1,
                            });
                          }}
                          disable={index === 0}
                          class="q-px-xs"
                          flat
                          dense
                          color="primary"
                        >
                          <q-icon
                            style="font-size: 12px"
                            name="fas fa-arrow-up"
                          />
                        </q-btn>
                        <q-btn
                          onClick={(e: Event) => {
                            e.stopPropagation();
                            this.$emit("moveArrItem", {
                              path,
                              index,
                              offset: 1,
                            });
                          }}
                          disable={index === valueArr.length - 1}
                          class="q-px-xs"
                          flat
                          dense
                          color="primary"
                        >
                          <q-icon
                            style="font-size: 12px"
                            name="fas fa-arrow-down"
                          />
                        </q-btn>
                      </div>
                    </div>
                  </template>
                  {confs.map((conf) => formBuilder(conf, [...path, index]))}
                </q-expansion-item>
              ))}
            </transition-group>
          </draggable>
          <q-btn
            dense
            flat
            color="primary"
            onClick={() => this.$emit("addArrItem", path)}
          >
            <q-icon name="fas fa-plus" size="0.9rem" class="q-pr-sm" />
            添加项目
          </q-btn>
        </div>
      );
    };

    return (
      <div>{this.templateStructure.map((node: any) => formBuilder(node))}</div>
    );
  }
}
