import { render, staticRenderFns } from "./ResourceManger.vue?vue&type=template&id=5ead9f67&"
import script from "./ResourceManger.vue?vue&type=script&lang=ts&"
export * from "./ResourceManger.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QBtn,QIcon,QScrollArea,QList,QItem,QColor,QTooltip,QDialog,QCard,QLinearProgress});qInstall(component, 'directives', {ClosePopup});
