
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "empty-no-data",
})
export default class EmptyNoData extends Vue {
  public title = "未选择组件";
  public subtitle = "请在左侧选择列表内容，进行编辑";

  render() {
    return (
      <div class="fit column items-center justify-center">
        <div class="empty-no-data-img"></div>
        <div class="text-h4 text-bold text-primary q-ma-md">{this.title}</div>
        <div class="text-subtitle1 text-bold">{this.subtitle} </div>
      </div>
    );
  }
}
