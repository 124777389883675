const log = console.log;

export default class StyleLog {
  private _baseStyle = ["padding: 2px 5px", "border-radius: 3px"];
  private _normalStyle = ["color: #000;background-color: transparent"];

  private _tag(
    text: string,
    bgColor: string,
    frColor: string,
    customStyles: string[] = []
  ): string[] {
    return [
      `%c${text}`,
      [
        ...this._baseStyle,
        ...customStyles,
        `background-color: ${bgColor}`,
        `color: ${frColor}`,
      ].join(";"),
    ];
  }

  // 输出tag
  public tag(
    text: string,
    bgColor: string,
    frColor: string,
    customStyles: string[] = []
  ): void {
    log(...this._tag(text, bgColor, frColor, customStyles));
  }
  // 带前缀标签的 tag
  public tagPrefix(
    label: string,
    text: string,
    bgColor: string,
    frColor: string,
    customStyles: string[] = []
  ): void {
    const prefixStyle = this._tag(label, bgColor, frColor, customStyles);
    log(
      `${prefixStyle[0]}%c ${text}`,
      prefixStyle[1],
      this._normalStyle.join(";")
    );
  }

  public danger(text: string): void {
    this.tag(text, "#C0392B", "#fff");
  }
  public dangerPrefix(text: string, tagLabel = "Error") {
    this.tagPrefix(tagLabel, text, "#C0392B", "#fff");
  }

  public success(text: string): void {
    this.tag(text, "#16A085", "#fff");
  }
  public successPrefix(text: string, tagLabel = "Success") {
    this.tagPrefix(tagLabel, text, "#16A085", "#fff");
  }

  public warn(text: string): void {
    this.tag(text, "#F1C40F", "#000");
  }
  public warnPrefix(text: string, tagLabel = "Warning") {
    this.tagPrefix(tagLabel, text, "#F1C40F", "#000");
  }

  public normal(text: string): void {
    this.tag(text, "#3498DB", "#fff");
  }
  public normalPrefix(text: string, tagLabel = "StyleLog") {
    this.tagPrefix(tagLabel, text, "#3498DB", "#fff");
  }
}

export const styleLog = new StyleLog();
