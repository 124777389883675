export const createRandomText = (len = 32, unique = true): string => {
  const dict_1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const dict_2 = 'abcdefghijklmnopqrstuvwxyz';
  const dict_3 = '0123456789';
  let res = '';

  const now = unique ? `${Date.now()}` : '';
  for (let i = 0; i < len - now.length; i++) {
    const dict = i === 0 ? dict_1 + dict_2 : dict_1 + dict_2 + dict_3;
    const index = Math.max(
      0,
      Math.min(dict.length - 1, Math.floor(Math.random() * dict.length)),
    );
    res += dict.substr(index, 1);
  }
  for (let i = 0; i < now.length; i++) {
    const _i = parseInt(now.substr(i, 1));
    res += dict_2.substr(_i, 1);
  }
  return res;
};
