



























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class TemplateItem extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly template!: Record<string, any>;
}
