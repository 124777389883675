
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import TemplateItemVue from "@/components/TemplateItem.vue";
import ResourceManger from "@/components/ResourceManger.vue";
import ComponentItemVue from "@/components/ComponentItem.vue";
import { fetchTemplates } from "@/api/endpoints/template/fetchTemplates";
import { fetchCustomTpls } from "@/api/endpoints/template/fetchCustomTpls";
import { TemplateEntity } from "../../../types/templates/template.interface";
import { fetchPageComponents } from "@/api/endpoints/page/fetchPageComponents";
import { ComponentItem } from "../../../types/components/component-item.interface";
import { updatePageComponents } from "@/api/endpoints/page/updatePageComponents";
import { SET_APP_ID } from "@/store/mutation.type";
import { createRandomText } from "@/utils/random-text";
import ComponentPreviewer from "@/components/ComponentPreviewer.vue";
import EmptyNoData from "../components/EmptyNoData.vue";
import draggable from "vuedraggable";

const generateLocateRaw = (
  path: (string | number)[],
  tailIgnore = 0
): string => {
  let locateStr = "";
  for (let key of path.slice(0, path.length - tailIgnore)) {
    const isNum = Object.prototype.toString.call(key) === "[object Number]";
    locateStr += `[${isNum ? key : `"${key}"`}]`;
  }
  return locateStr;
};

@Component({
  name: "edit-page",
  components: {
    draggable,
  },
})
export default class EditPage extends Vue {
  public components: Array<ComponentItem> = [];
  public templates: Array<TemplateEntity> = [];
  public componentsOriginRaw = "[]";
  public showPreviewer = false;
  public showResourceManger = false;
  public previewComponent?: ComponentItem = undefined;
  public pageName = "";
  private currentCom?: ComponentItem = undefined;
  private currentTem?: TemplateEntity = undefined;
  private currentIndex = NaN;

  get pageId() {
    return this.$route.params.pageId;
  }

  get edited() {
    return this.componentsOriginRaw !== JSON.stringify(this.components);
  }

  async created() {
    await this.getTemplatesOnline();
  }
  @Watch("pageId", { immediate: true })
  public onPageIdChanged() {
    this.getComponentsOnline();
  }

  public async getComponentsOnline() {
    if (this.components) this.components.splice(0);
    const response: any = await fetchPageComponents(this.pageId);
    if (response.status === 0) {
      this.components = response.data.components || [];
      this.pageName = response.data.page_name;
      const appId = response.data.applicationAppId;
      this.$store.commit(SET_APP_ID, appId);

      this.currentCom = null;
      this.currentTem = null;
      this.currentIndex = NaN;

      this.componentsOriginRaw = JSON.stringify(this.components);
      this.$nextTick(() => {
        if (window.top) {
          window.top.postMessage("components_loaded", "*");
        }
      });
    }
  }
  public async getTemplatesOnline() {
    let presetTpls = [];
    let customTpls = [];
    try {
      const response: any = await fetchTemplates();
      if (response.status !== 0) throw Error(JSON.stringify(response));
      presetTpls = response.data;
    } catch (err) {
      console.log(err);
    }

    try {
      const response: any = await fetchCustomTpls(this.pageId);
      if (response.status !== 0) throw Error(JSON.stringify(response));
      customTpls = response.data.map((el) => ({
        ...el,
        _custom: true,
      }));
    } catch (err) {
      console.log(err);
    }
    this.templates = presetTpls.concat(customTpls);
  }

  public async submitComponents() {
    this.$q
      .dialog({
        title: "是否提交修改",
        message:
          "请确认是否提交数据，提交数据后，用户使用的网页内容将会相应更新",
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const response = await updatePageComponents(
          this.pageId,
          this.components,
          this.$route.query.token
        );
        if (response.status === 0) {
          // 重新获取
          await this.getComponentsOnline();
        } else {
          this.$q.notify({
            type: "danger",
            message: "更新出错，" + (response.detail || response.message),
          });
        }
      });
  }

  public cancelComponents() {
    this.$q
      .dialog({
        title: "是否取消所有修改？",
        message:
          "请确认是否取消所有修改？该操作不可逆。确认后将数据恢复为上次保存时的状态。",
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.components = JSON.parse(this.componentsOriginRaw);
      });
  }

  /**
   * 从模板创建组件
   */
  public createNewComponent(templateId: string) {
    const template: any = this.templates.find(
      (tpl: any) => tpl.template_id == templateId
    );
    const id = createRandomText(16, true);
    this.components.push({
      id,
      template_id: templateId,
      // 需要深拷贝
      config: JSON.parse(JSON.stringify(template.demo)),
    });
  }

  public createCustomNewComponent(customTpl: { label: string; value: string }) {
    const id = createRandomText(16, true);
    this.components.push({
      id,
      custom: true,
      label: customTpl.label,
      value: customTpl.value,
    } as any);
  }

  /**
   * 添加数组项
   */
  public handleAddArrItem(
    path: (string | number)[],
    template: TemplateEntity,
    com_index: number
  ) {
    const locateStr = generateLocateRaw(path);
    const locateStr2 = generateLocateRaw(path, 1);
    new Function(
      "$vm",
      "com",
      "key",
      `
      if (!com.config${locateStr}) $vm.$set(com.config${locateStr2}, key, []);
      com.config${locateStr}.push({});
    `
    )(this, this.components[com_index], path[path.length - 1]);
  }

  /**
   * 处理直接更新值
   */
  public hanleUpdateValue(
    com_index: number,
    path: (string | number)[],
    value: any
  ) {
    const locateStr = generateLocateRaw(path, 1);
    new Function(
      "$vm",
      "com",
      "key",
      "value",
      `$vm.$set(com.config${locateStr}, key, value)`
    )(this, this.components[com_index], path[path.length - 1], value);
  }

  /**
   * 处理数组中某一项的删除
   */
  public handleRemoveArrItem(
    com_index: number,
    path: (string | number)[],
    index: number
  ) {
    const locateStr = generateLocateRaw(path);
    new Function("com", "index", `com.config${locateStr}.splice(index, 1)`)(
      this.components[com_index],
      index
    );
  }

  /**
   * 移动数组项
   */
  public handleMoveArrItem(
    com_index: number,
    path: (string | number)[],
    index: number,
    offset: number
  ) {
    const locateStr = generateLocateRaw(path, -1);
    new Function(
      "com",
      "index",
      "offset",
      `
      const _arr = com.config${locateStr}
      const _val = _arr.splice(index, 1)[0];
      _arr.splice(
        Math.min(
          _arr.length,
          Math.max(
            0,
            index + offset,
          )
        ),
        0,
        _val
      )
    `
    )(this.components[com_index], index, offset);
  }

  /**
   * 移动一个组件
   */
  public handleMoveComItem(e: Event, com_index: number, offset: number) {
    const _val = this.components.splice(com_index, 1)[0];
    e.stopPropagation();

    this.components.splice(
      Math.min(this.components.length, Math.max(0, com_index + offset)),
      0,
      _val
    );
    this.currentIndex = com_index + offset;
  }

  /**
   * 删除组件
   */
  public handleRemoveComItem(com_index: number) {
    this.$q
      .dialog({
        title: "是否删除该组件",
        message: "删除后相关数据可能无法恢复，需要重新编辑，请确认",
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        this.components.splice(com_index, 1);
        this.currentIndex = NaN;
      });
  }

  public setCurrent(com: ComponentItem, tem: TemplateEntity, index) {
    this.currentCom = com;
    this.currentTem = tem;
    this.currentIndex = index;
  }

  public copyComponents(context: ComponentItem[]) {
    const stringContext = JSON.stringify(context, undefined, 2);
    const aux = document.createElement("textarea");
    aux.value = stringContext;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    this.$q.notify({ color: "primary", message: "拷贝成功" });
  }

  /**
   * 导出页面配置
   */
  public exportPageConfig(config: any) {
    const raw = JSON.stringify(config);
    const blob = new Blob([raw], { type: "text/json" });
    const a = document.createElement("a");
    a.target = "_blank";
    a.download = `${this.pageName}.json`;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
    a.click();
    window.URL.revokeObjectURL(a.href);
  }

  public importPageConfig(e) {
    const files = ((e || {}).target || {}).files;
    const file = files[0];
    this.$q
      .dialog({
        title: `确认导入${file.name}吗？`,
        message: this.edited
          ? "将覆盖当前页面的所有内容, 您当前有未保存的改动，该内容将无法恢复，请谨慎操作！"
          : "将覆盖当前页面的所有内容",
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const reader = new FileReader();
        reader.onload = (_e) => {
          const obj = JSON.parse(_e.target.result);
          this.currentCom = null;
          this.currentTem = null;
          this.currentIndex = NaN;
          this.components = obj;
        };
        reader.readAsText(file);
      });
  }

  render() {
    const {
      components,
      pageId,
      currentIndex,
      currentCom,
      currentTem,
      pageName,
      templates,
      edited,
    } = this;
    return (
      <div class="fit">
        <q-dialog
          class="bg-white fullwidth fullheight text-white"
          maximized={true}
          v-model={this.showPreviewer}
        >
          <q-bar class="bg-grey-8 text-white q-ma-none" style="height: 30px">
            <q-space />
            <q-btn dense flat v-close-popup>
              <q-icon class="text-white" name="fas fa-times" size="1rem" />
              <q-tooltip class="bg-white text-primary">Close</q-tooltip>
            </q-btn>
          </q-bar>
          <ComponentPreviewer component={this.previewComponent} />
        </q-dialog>

        <q-dialog v-model={this.showResourceManger}>
          <q-card
            style="width: 90vw; max-width: 1500px; height: 90vh"
            class="bg-white"
          >
            <div class="fit column no-wrap">
              <header class="row justify-between items-center no-wrap q-pa-sm bg-grey-3">
                <span class="text-h5 text-blod text-grey-10 q-pl-md">
                  图片资源库
                </span>
                <q-btn flat v-close-popup dense class="q-px-xs q-py-xs">
                  <q-icon size="18px" name="fas fa-times" />
                </q-btn>
              </header>
              <main style=" height: 0;" class="col-grow">
                <ResourceManger />
              </main>
            </div>
          </q-card>
        </q-dialog>

        <header class="fixed full-width row justify-between q-pa-md">
          <span></span>
          <div class="text-grey-5">
            <q-btn
              dense
              class="q-pa-xs bg-grey-4 text-grey-6 q-ml-xs"
              flat
              onClick={() => this.exportPageConfig(components)}
            >
              <q-icon size="18px" name="fas fa-file-export" />
              <q-tooltip>导出该页面的配置到本地</q-tooltip>
            </q-btn>

            <q-btn
              dense
              class="q-pa-xs bg-grey-4 text-grey-6 q-ml-xs relative-position"
              flat
            >
              <q-icon size="18px" name="fas fa-file-import" />
              <q-tooltip>导入配置到该页面</q-tooltip>
              <input
                accept="application/json"
                type="file"
                class="absolute fit"
                style="opacity: 0"
                onInput={(e) => this.importPageConfig(e)}
              />
            </q-btn>
            <q-btn
              dense
              class="q-px-xs q-py-xs  bg-grey-4 text-grey-6 q-ml-xs"
              flat
            >
              <q-icon size="18px" name="fas fa-file-code" />
              <q-tooltip>如果遇到问题，可以查看code，联系开发人员</q-tooltip>

              <q-menu max-height="90vh" max-width="50vw">
                <q-btn
                  onClick={() => this.copyComponents(components)}
                  flat
                  class="bg-primary text-white rounded-borders q-mx-lg fixed text-caption"
                >
                  <q-icon name="fas fa-clipboard" size="1rem" class="q-pr-sm" />
                  拷贝到剪贴板
                </q-btn>

                <div
                  style="line-height: 1.2; white-space: pre; min-width: 300px; overflow-x: auto"
                  class="bg-grey-1 q-pa-md text-caption"
                >
                  <p class="text-red text-bold q-mt-lg">JSON Data:</p>
                  {JSON.stringify(components, undefined, 2)}
                </div>
              </q-menu>
            </q-btn>
          </div>
        </header>
        <div class="row no-wrap fit">
          {/**/}
          <q-layout
            view="lHh lpr lFf"
            container
            style="height: 100vh; width: 450px; min-width: 450px"
          >
            {/* 顶部标题信息 */}
            <q-header class="bg-white q-pa-md">
              <div class="row justify-between items-center bg-white">
                <span class="text-h5 text-bold text-grey-6 ">
                  页面布局 / {pageName}
                </span>

                <q-btn
                  flat
                  dense
                  color="primary"
                  onClick={() => (this.showResourceManger = true)}
                  class="q-px-sm"
                >
                  图片资源库
                </q-btn>
              </div>

              <section class=" text-caption text-weight-bold text-grey-7 q-my-lg">
                当前板块共有{" "}
                <span class="text-red-5 text-bold q-ma-xs q-pa-xs rounded-borders bg-red-1">
                  {" "}
                  {components.length}
                </span>
                个组件
              </section>
              <q-separator class="q-my-md" />
            </q-header>

            {/* 组件列表 */}
            <q-page-container class="q-pa-md">
              <q-list class=" q-mb-md">
                <draggable list={components}>
                  <transition-group>
                    {components.map((com, com_index) => {
                      const template = templates.find(
                        (tpl) => tpl.template_id === com.template_id
                      );
                      if (!com) return <div></div>;
                      return (
                        <div
                          key={com.id}
                          class={{
                            "edit-page-com-active-list":
                              com?.id === currentCom?.id ? true : false,
                            "edit-page-com-list": true,
                            row: true,
                            "justify-between": true,
                            "items-center": true,
                            "q-mb-md": true,
                            "q-pa-sm": true,
                            "rounded-borders": true,
                            "no-wrap": true,
                          }}
                          onClick={() =>
                            this.setCurrent(com, template, com_index)
                          }
                          style="width: 370"
                        >
                          <div class="row no-wrap items-center">
                            <div
                              class="bg-white rounded-borders row no-wrap justify-center "
                              style="width: 68px; height: 45px"
                            >
                              <img
                                src={template?.thumbnail}
                                style="max-width: 80%;max-height: 80%; display: block; margin: auto"
                              />
                            </div>
                            <div class="column items-left text-left  q-ml-md">
                              <span class="text-bold ">
                                {template?.template_name || com["label"]}
                              </span>
                              <span
                                style="font-size: 0.6rem"
                                class="text-weight-light"
                              >
                                ID: {com.id}{" "}
                              </span>
                            </div>
                            {com["custom"] ? (
                              <q-badge class="q-ml-xs bg-primary">
                                自定义
                              </q-badge>
                            ) : (
                              <q-badge class="q-ml-xs bg-grey-5">
                                {template?.__version__}
                              </q-badge>
                            )}
                          </div>
                          <div class="row items-center no-wrap">
                            <q-btn
                              disable={com["custom"]}
                              class="q-px-xs"
                              flat
                              dense
                              color="primary"
                              onClick={() => {
                                this.previewComponent = com;
                                this.showPreviewer = true;
                              }}
                            >
                              <q-icon size="12px" name="fas fa-eye" />
                            </q-btn>
                            <q-btn
                              onClick={(e) =>
                                this.handleMoveComItem(e, com_index, -1)
                              }
                              disable={com_index === 0}
                              class="q-px-xs"
                              flat
                              dense
                              color="primary"
                            >
                              <q-icon size="12px" name="fas fa-arrow-up" />
                            </q-btn>
                            <q-btn
                              onClick={(e) =>
                                this.handleMoveComItem(e, com_index, 1)
                              }
                              disable={com_index === components.length - 1}
                              class="q-px-xs"
                              flat
                              dense
                              color="primary"
                            >
                              <q-icon size="12px" name="fas fa-arrow-down" />
                            </q-btn>
                            <q-btn
                              onClick={() =>
                                this.handleRemoveComItem(com_index)
                              }
                              class="q-px-xs"
                              flat
                              dense
                              color="red"
                            >
                              <q-icon size="12px" name="fas fa-trash" />
                            </q-btn>
                          </div>
                        </div>
                      );
                    })}
                  </transition-group>
                </draggable>
              </q-list>

              {/* 添加按钮 */}
              <div class="full-width flex justify-center ">
                <q-btn
                  flat
                  class="bg-primary"
                  style="min-width: 180px"
                  color="white"
                >
                  <q-icon name="fas fa-plus" size="0.8rem" class="q-pr-sm" />
                  <span>添加</span>
                  {/* 组件菜单 */}
                  <q-menu offset={[0, 5]}>
                    <q-list
                      class="q-pa-md row"
                      style="min-width: 600px; min-height: 400px;"
                    >
                      {templates.map((tpl: any) => (
                        <TemplateItemVue
                          class="q-ml-md q-mb-md"
                          onClick={() =>
                            tpl._custom
                              ? this.createCustomNewComponent(tpl)
                              : this.createNewComponent(tpl.template_id)
                          }
                          template={tpl}
                        />
                      ))}
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </q-page-container>

            {/* 底部更新按钮 */}
            <q-footer class="bg-white q-pa-md flex justify-between ">
              <q-btn
                onClick={() => this.cancelComponents()}
                color="red"
                outline
                disable={!edited}
                class="q-px-lg"
              >
                取消
              </q-btn>

              <q-btn
                onClick={() => this.submitComponents()}
                color="primary"
                rounded
                disable={!edited}
                class="q-px-lg"
              >
                更新
              </q-btn>
            </q-footer>
          </q-layout>

          <div class="bg-grey-2 fit" style="min-width: 300px; overflow-x: auto">
            {(currentIndex || currentIndex === 0) &&
            currentTem &&
            components[currentIndex] &&
            !components[currentIndex]["custom"] ? (
              <div>
                <header class="row justify-between items-center q-pa-lg">
                  <span class="text-h5 text-bold text-grey-6">
                    内容编辑 / {currentTem.template_name}
                  </span>
                </header>
                <ComponentItemVue
                  config={currentCom.config}
                  template-structure={(currentTem || ({} as any)).structure}
                  onAddArrItem={(path: any) =>
                    this.handleAddArrItem(path, currentTem as any, currentIndex)
                  }
                  onRemoveArrItem={({ path, index }: any) =>
                    this.handleRemoveArrItem(currentIndex, path, index)
                  }
                  onMoveArrItem={({ path, index, offset }: any) => {
                    this.handleMoveArrItem(currentIndex, path, index, offset);
                  }}
                  onUpdateValue={({ path, value }: any) => {
                    this.hanleUpdateValue(currentIndex, path, value);
                  }}
                />
              </div>
            ) : (
              <EmptyNoData />
            )}
          </div>
        </div>
      </div>
    );
  }
}
