import { SET_OSS_STS_CLIENT } from "../mutation.type";
import Vue from "vue";

const state = {
  stsClient: null,
};

const mutations = {
  [SET_OSS_STS_CLIENT]: (state, client) => {
    state.stsClient = client;
  },
};

export default {
  state,
  mutations,
};
