import { ComponentItem } from './../../../../../types/components/component-item.interface';
import { ResponseBody } from "../../../../../types/response/response-body.interface";
import { ApiService } from "@/api";

export const fetchPageComponents = (
  pageId: string,
  join = {
    application: false,
  }
): Promise<ResponseBody<ComponentItem[]>> => {
  return ApiService.baseAction(`page/${pageId}`, {
    params: {
      application: join?.application ? true : "",
    },
  });
};
