var render, staticRenderFns
import script from "./EditPage.vue?vue&type=script&lang=tsx&"
export * from "./EditPage.vue?vue&type=script&lang=tsx&"
import style0 from "./EditPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QList from 'quasar/src/components/item/QList.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QBar,QSpace,QBtn,QIcon,QTooltip,QCard,QMenu,QLayout,QHeader,QSeparator,QPageContainer,QList,QBadge,QFooter});qInstall(component, 'directives', {ClosePopup});
