import "./styles/quasar.scss";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

import Notify from 'quasar/src/plugins/Notify.js';;
import Dialog from 'quasar/src/plugins/Dialog.js';;
import Quasar from 'quasar/src/vue-plugin.js';;
import Vue from "vue";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import lang from "quasar/lang/zh-hans.js";

Notify.setDefaults({
  position: "top",
  timeout: 2500,
  classes: "rounded-borders",
});

Vue.use(Quasar, {
  config: {
    notify: {},
  },
  plugins: {
    Notify,
    Dialog,
  },
  lang,
  iconSet,
  animations: 'all'
});

Notify.registerType("success", {
  icon: "fas fa-check",
  progress: true,
  color: "green",
  position: "top",
  timeout: 3000,
});
Notify.registerType("danger", {
  icon: "fas fa-bug",
  progress: true,
  color: "red-7",
  position: "top",
  timeout: 3000,
});
Notify.registerType("warn", {
  icon: "fas fa-engine-warning",
  progress: true,
  color: "amber-8",
  position: "top",
  timeout: 3000,
});
Notify.registerType("normal", {
  progress: true,
  color: "primary",
  position: "top",
  timeout: 3000,
});
Notify.registerType("loading", {
  progress: false,
  color: "primary",
  position: "top",
  timeout: 1000,
});
